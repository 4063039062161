import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { BoxLineGeometry } from "three/examples/jsm/geometries/BoxLineGeometry.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

const dur = 0.4;
let lastItems = [];
let lastIndex = -1;

const listItems = gsap.utils.toArray(".info");
listItems.forEach((item, i) => {
  item.addEventListener("click", () => {
    const parent = item.querySelector(".info__content");
    const itemTargets = gsap.utils.toArray(
      parent.querySelectorAll(["video", "h1", "h2", "p"])
    );
    const isSameAsLast = i === lastIndex && listItems[lastIndex];

    // Get all the items that are changing this click
    const targets = isSameAsLast
      ? listItems.concat(itemTargets)
      : listItems.concat(itemTargets.concat(lastItems));

    // grab the current state of the targets (before changing)
    const state = Flip.getState(targets);

    // Animate out the last clicked item if it's not the same as the current
    if (!isSameAsLast && listItems[lastIndex]) {
      listItems[lastIndex].classList.remove("expanded");
    }

    // Toggle the display on the clicked item
    listItems[i].classList.toggle("expanded");

    Flip.from(state, {
      duration: dur,
      ease: "power3.inOut",
      absolute: true,
      nested: true,
      onEnter: (elements) => {
        console.log(elements);
        gsap.fromTo(
          elements,
          { filter: "blur(8px)", opacity: 0 },
          {
            filter: "blur(0px)",
            opacity: 1,
            stagger: 0.04,
            ease: "linear",
            duration: dur,
            delay: 0.4,
          }
        );
      },
      onLeave: (elements) => {
        console.log(elements);
        gsap.fromTo(
          elements,
          {
            filter: (i, el) => state.getProperty(el, "filter"),
            opacity: (i, el) => state.getProperty(el, "opacity"),
          },
          { filter: "blur(8px)", opacity: 0, duration: dur }
        );
      },
    });

    // Update our variables
    lastItems = itemTargets;
    lastIndex = i;
  });
});

// --------------------------------------------------
// Variables
// --------------------------------------------------

let camera, scene, renderer, model, controls;
let room, floor;
let mixer = null;
let ambientLight, spotLight;
let actions = {};
const clock = new THREE.Clock();
const loadingManager = new THREE.LoadingManager();

init();
function init() {
  // --------------------------------------------------
  // Renderer
  // --------------------------------------------------

  renderer = new THREE.WebGLRenderer({
    antialias: true,
    powerPreference: "high-performance",
  });
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.physicallyCorrectLights = true;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.toneMapping = THREE.ReinhardToneMapping;
  renderer.toneMappingExposure = 0.8;
  renderer.setAnimationLoop(render);

  document.body.appendChild(renderer.domElement);

  // --------------------------------------------------
  // Scene
  // --------------------------------------------------

  scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);
  scene.backgroundBlurriness = 0.5;
  //scene.fog = new THREE.Fog(0xcccccc, 10, 15);

  // --------------------------------------------------
  // Environment
  // --------------------------------------------------

  new RGBELoader(loadingManager)
    .setPath("./assets/hdr/")
    .load("venice_sunset_1k.hdr", function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      scene.background = texture;
      scene.environment = texture;
    });

  // --------------------------------------------------
  // Camera
  // --------------------------------------------------

  camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  camera.position.set(1, 0.5, 6);
  camera.lookAt(scene.position);

  // --------------------------------------------------
  // Lighting
  // --------------------------------------------------

  // Ambient
  ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
  scene.add(ambientLight);

  // Spotlight
  spotLight = new THREE.SpotLight(0xffffff, 30);
  spotLight.position.set(-2.5, 5, 2.5);
  spotLight.angle = Math.PI / 6;
  spotLight.penumbra = 1;
  spotLight.decay = 1;
  spotLight.distance = 0;
  spotLight.castShadow = true;
  spotLight.shadow.bias = -0.001;
  spotLight.shadow.mapSize.width = 2048;
  spotLight.shadow.mapSize.height = 2048;
  spotLight.shadow.camera.near = 1;
  spotLight.shadow.camera.far = 50;
  spotLight.shadow.focus = 1;
  scene.add(spotLight);

  // --------------------------------------------------
  // Controls
  // --------------------------------------------------

  controls = new OrbitControls(camera, renderer.domElement);
  controls.enablePan = false;
  controls.enableZoom = false;
  controls.enableRotate = false;
  controls.enableDamping = true;
  controls.minDistance = 4;
  controls.maxDistance = 10;
  controls.zoomSpeed = 1;
  controls.autoRotate = true;
  controls.autoRotateSpeed = 0.5;
  controls.maxPolarAngle = Math.PI / 2;
  controls.maxAzimuthAngle = Math.PI / 2;
  controls.update();

  // --------------------------------------------------
  // Room
  // --------------------------------------------------

  room = new THREE.LineSegments(
    new BoxLineGeometry(30, 30, 30, 10, 10, 10).translate(0, 13, 0),
    new THREE.LineBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.3,
    })
  );
  room.opacity = 0.1;
  scene.add(room);

  // --------------------------------------------------
  // Floor
  // --------------------------------------------------

  const floorGeometry = new THREE.PlaneGeometry(30, 30);
  const floorMaterial = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0.25,
  });

  floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.position.set(0, -2, 0);
  floor.name = "floor";
  floor.rotation.x = -Math.PI / 2;
  floor.receiveShadow = true;
  scene.add(floor);

  // --------------------------------------------------
  // Loading Manager
  // --------------------------------------------------

  loadingManager.onLoad = function () {
    document.body.classList.remove("loading");
  };

  // --------------------------------------------------
  // Model Loaders
  // --------------------------------------------------

  // Draco Loader
  const draco = new DRACOLoader();
  draco.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
  );
  draco.setDecoderConfig({ type: "js" });

  // GLTF Loader
  const loader = new GLTFLoader(loadingManager)
    .setPath("./assets/models/")
    .setDRACOLoader(draco);

  // Assets
  const models = {
    models: [
      {
        name: "Stuart",
        gltf: "dancer.glb",
        position: { x: 0, y: -2, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: 2,
      },
    ],
  };

  // Load Assets
  Object.keys(models).forEach((key) => {
    models[key].forEach((m) => {
      loader.load(m.gltf, async (gltf) => {
        model = gltf.scene;
        mixer = new THREE.AnimationMixer(model);
        let animations = gltf.animations;
        //console.log(animations);
        model.traverse((obj) => {
          if (obj.isMesh) {
            if (obj.name == "Wolf3D_Head") {
              obj.morphTargetInfluences[1] = 0.25; // smile
            }
            obj.castShadow = true;
            obj.receiveShadow = true;
            if (obj.material.map) {
              obj.material.map.anistrophy = 16;
            }
          }
        });
        model.name = m.name;
        model.position.set(m.position.x, m.position.y, m.position.z);
        model.rotation.set(m.rotation.x, m.rotation.y, m.rotation.z);
        model.scale.multiplyScalar(m.scale);
        scene.add(model);
        if (animations.length > 0) {
          animations.forEach((pose) => {
            let clip = pose;
            let action = mixer.clipAction(pose);
            actions[clip.name] = action;
          });
          actions["Armature|mixamo.com|Layer0"].play();
          //   mixer.addEventListener("finished", (e) => {
          //     console.log(`Finished Playing: ${e.action._clip.name}`);
          //   });
        }
      });
    });
  });

  // --------------------------------------------------
  // Resize
  // --------------------------------------------------

  window.addEventListener("resize", onWindowResize);

  // Resize
  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  // --------------------------------------------------
  // Render Loop
  // --------------------------------------------------

  function render() {
    const d = clock.getDelta();
    const t = clock.getElapsedTime();
    controls.update();
    if (model) {
      let x = model.position.x;
      let y = model.position.y + 1.7;
      let z = model.position.z;
      camera.lookAt(x, y, z);
    }
    if (mixer) {
      mixer.update(d);
    }
    renderer.render(scene, camera);
  }
}
